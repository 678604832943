<template>
  <div class="payment-page">
    <b-input-group class="input-group-merge round search-input-filter">
      <b-input-group-prepend
        is-text
      >
        <VSIcons
          name="Search"
          color="#7D8E9E"
          width="18"
          height="18"
        />
      </b-input-group-prepend>
      <b-form-input
        id="filter-input"
        v-model="filter"
        placeholder="Buscar..."
        type="search"
      />
    </b-input-group>
    <b-table
      id="payment-table"
      ref="payment"
      class="details-table mt-4"
      :items="items"
      :fields="fields"
      hover
      responsive
      details-td-class="align-middle"
      :busy="apiInCall"
      :per-page="perPage"
      :current-page="currentPage"
      :filter="filter"
      :filter-included-fields="filterOn"
      empty-filtered-text="Não há resultados para sua pesquisa."
      empty-text="Não há registros para serem exibidos."
      show-empty
      @filtered="onFiltered"
    >
      <template #table-busy>
        <b-spinner variant="primary" />
      </template>
      <template #head(select)>
        <b-form-checkbox
          v-model="allSelected"
          :indeterminate="indeterminate"
          @change="toggleAll('payment', '')"
        />
      </template>
      <template #cell(select)="row">
        <b-form-checkbox
          v-model="selected"
          :value="row.item"
          @change="setItensToDelete({ itens: selected, api: '', fetchTable: 'Receivment' })"
        />
      </template>
      <template #cell(due_date)="row">
        <div class="d-flex align-items-center justify-content-center">
          <span class="validade">{{ row.item.due_date | formatLocalDate }}</span>
          <div>
            <VSIcons
              name="calendar"
              height="18"
            />
          </div>
        </div>
      </template>
      <template #cell(payment_date)="row">
        <div
          v-if="row.item.payment_date"
          class="d-flex align-items-center justify-content-center"
        >
          <span class="validade">{{ row.item.payment_date | formatLocalDate }}</span>
          <div>
            <VSIcons
              name="calendar"
              height="18"
            />
          </div>
        </div>
        <span v-else>{{ 'Não consta' }}</span>
      </template>
      <template #cell(issue_date)="row">
        <div class="d-flex align-items-center justify-content-center">
          <span class="validade">{{ row.item.issue_date | formatLocalDate }}</span>
          <div>
            <VSIcons
              name="calendar"
              height="18"
            />
          </div>
        </div>
      </template>
      <template #cell(account)="row">
        {{ row.item.account.person_full_name || row.item.account.corporate_name }}
      </template>
      <template #cell(plan_recurrence)="row">
        {{ row.item.account.plan_recurrence }}
      </template>
      <template #cell(account_cnpj)="row">
        {{ row.item.account.cpf || row.item.account.cnpj }}
      </template>
      <template #cell(account_plan)="row">
        {{ row.item.account.plan_id }}
      </template>
      <template #cell(payment_amount)="row">
        R${{ row.item.payment_amount | formatPrice }}
      </template>
      <template #cell(actions)="row">
        <b-dropdown
          no-caret
          right
          variant="light"
          menu-class="dropdown-table-actions"
          toggle-class="button-dropdown-actions"
        >
          <template #button-content>
            <VSIcons
              name="Actions"
              width="5"
              height="22"
            />
          </template>
          <b-dropdown-item
            class="dropdown-table-item"
            @click="openBoleto(row.item.invoice_link)"
          >
            <VSIcons
              name="DocumentText"
              width="20"
              height="20"
              color="#7D8E9E"
            />
            <span>
              Segunda via
            </span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="company-table"
    >
      <template #prev-text>
        <VSIcons name="ArrowLeft" />
      </template>
      <template #next-text>
        <VSIcons name="ArrowRight" />
      </template>
    </b-pagination>
  </div>
</template>

<script>
import {
  BInputGroup, BInputGroupPrepend, BFormInput, BTable, BFormCheckbox, BSpinner, BPagination, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { tableConfig } from '@core/mixins/ui/forms'
import { formatPrice, formatLocalDate } from '@core/utils/filter'
import VSIcons from '@/assets/icons/VSIcons.vue'

export default {
  components: {
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BTable,
    VSIcons,
    BFormCheckbox,
    BSpinner,
    BPagination,
    BDropdown,
    BDropdownItem,
  },
  filters: {
    formatPrice,
    formatLocalDate,
  },
  mixins: [
    tableConfig,
  ],
  data() {
    return {
      fields: [
        {
          label: '',
          key: 'select',
        },
        {
          label: 'Situação',
          key: 'status',
          sortable: true,
          tdClass: 'align-middle to-uppercase',
        },
        {
          label: 'Vencimento',
          key: 'due_date',
          sortable: true,
          tdClass: 'align-middle to-uppercase',
        },
        {
          label: 'Nome',
          key: 'account',
          sortable: true,
          tdClass: 'align-middle to-uppercase',
        },
        {
          label: 'CNPJ ou CPF',
          key: 'account_cnpj',
          sortable: true,
          tdClass: 'align-middle to-uppercase',
        },
        {
          label: 'Plano',
          key: 'account_plan',
          sortable: true,
          tdClass: 'align-middle to-uppercase',
        },
        {
          label: 'Recorrência',
          key: 'plan_recurrence',
          sortable: true,
          tdClass: 'align-middle to-uppercase',
        },
        {
          label: 'Valor',
          key: 'payment_amount',
          sortable: true,
          tdClass: 'align-middle to-uppercase',
        },
        {
          label: 'Data Pag.',
          key: 'payment_date',
          sortable: true,
          tdClass: 'align-middle to-uppercase',
        },
        {
          label: 'Emissão',
          key: 'issue_date',
          sortable: true,
          tdClass: 'align-middle to-uppercase',
        },
        {
          label: 'Forma de Pag.',
          key: 'payment_method',
          sortable: true,
          tdClass: 'align-middle to-uppercase',
        },
        {
          label: '',
          key: 'actions',
          tdClass: 'align-middle',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({ apiInCall: 'app/getApiInCall', items: 'accounts/getPayment' }),
  },
  created() { this.fetchPayment() },
  methods: {
    ...mapActions({ fetchPayment: 'accounts/fetchPayment' }),
    ...mapMutations({
      setItensToDelete: 'app/SET_ITENS_DELETE',
    }),
    openBoleto(link) {
      window.open(link)
    },
  },
}
</script>
