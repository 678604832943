<script> /* eslint-disable */</script>
<template>
  <div class="company-page">
    <div class="d-flex justify-content-between">
      <b-input-group class="input-group-merge round search-input-filter">
        <b-input-group-prepend is-text>
          <VSIcons
            color="#7D8E9E"
            height="18"
            name="Search"
            width="18"
          />
        </b-input-group-prepend>
        <b-form-input
          id="filter-input"
          v-model="filter"
          placeholder="Buscar..."
          type="search"
        />
      </b-input-group>
      <button
        class="btn btn-primary"
        @click=" isTrialButtonIsPressed = !isTrialButtonIsPressed"
      >
        Criar Conta Trial
      </button>
    </div>
    <div v-if="isTrialButtonIsPressed===true">
      <b-form-select
        v-model="user.plan_identifier"
        :options="trialPlansToSelect"
        class="w-25 mt-5 ml-3 mb-3"
      />
      <div
        v-if="user.plan_identifier!==''"
      >
        <div class="w-25 ">
          <h4>Dados do cliente</h4>
          <b-form-input
            v-model="user.name"
            :state="userValidation.name"
            placeholder="Nome ou Razão Social"
            class="m-3"
          />
          <b-form-input
            v-model="cpf_cnpjMask"
            v-mask="['###.###.###-##','##.###.###/####-##']"
            :state="userValidation.cpf_cnpj"
            placeholder="CPF ou CNPJ"
            class="m-3"
            @keydown="blockCtrlV"
          />
          <b-form-input
            v-model="user.email"
            :state="userValidation.email"
            placeholder="Email"
            type="email"
            class="m-3"
          />
          <b-form-input
            v-model="phoneMask"
            v-mask="'(##)#########'"
            :state="userValidation.phone"
            placeholder="(DDD) Telefone"
            class="m-3"
            @keydown="blockCtrlV"
          />
        </div>
        <div class="w-25">
          <h4>Endereço do cliente</h4>
          <b-form-input
            v-model="user.zip_code"
            :state="userValidation.zip_code"
            placeholder="CEP"
            class="mt-2 mb-2 ml-3"
            @change="getCep"
            @keydown="blockCtrlV"
          />
          <div
            v-if="dataCep"
            class="mt-2 mb-2 ml-2"
          >
            <b-form-input
              v-model="dataCep.logradouro"
              :state="userValidation.street"
              placeholder="Rua"
              class="mt-2 mb-2 ml-2"
            />
            <b-form-input
              v-model="user.number"
              :state="userValidation.number"
              placeholder="Número"
              class="mt-2 mb-2 ml-2"
            />
            <b-form-input
              v-model="user.complement"
              placeholder="Complemento"
              class="mt-2 mb-2 ml-2"
            />
            <b-form-input
              v-model="dataCep.bairro"
              :state="userValidation.district"
              placeholder="Bairro"
              class="mt-2 mb-2 ml-2"
            />
            <b-form-input
              v-model="dataCep.localidade"
              :state="userValidation.city"
              placeholder="Cidade"
              class="mt-2 mb-2 ml-2"
            />
            <b-form-input
              v-model="dataCep.uf"
              :state="userValidation.uf"
              placeholder="UF"
              class="mt-2 mb-2 ml-2"
            />
          </div>
        </div>
        <button
          class="btn btn-primary"
          :disabled="userValidation.buttonCep"
          @click="createTrialUser"
        >
          Criar Usuário
        </button>
      </div>
    </div>

    <div
      v-if="isTrialButtonIsPressed===false"
    >
      <b-table
        id="company-table"
        ref="companyTable"
        :busy="apiInCall"
        :current-page="currentPage"
        :fields="fields"
        :filter="filter"
        :filter-included-fields="filterOn"
        :items="items"
        :per-page="perPage"
        class="details-table mt-4"
        details-td-class="align-middle"
        empty-filtered-text="Não há resultados para sua pesquisa."
        empty-text="Não há registros para serem exibidos."
        hover
        responsive
        selectable
        show-empty
        @filtered="onFiltered"
        @row-selected="detailAccounts"
      >
        <template #table-busy>
          <b-spinner variant="primary" />
        </template>
        <template #cell(id)="row">
          {{ row.index + 1 }}
        </template>
        <template #cell(isActive)="row">
          {{ row.item.isActive ? "Ativo" : "Inativo" }}
        </template>
        <template #cell(name)="row">
          <span 
            v-if="row.item.corporate_name === null"
          >
            {{ row.item.person_full_name }}
          </span>
          <span v-else>{{ row.item.corporate_name }}</span>
        </template>
        <template #cell(plan)="row">
          {{ row.item.plan.plan_name }}
        </template>
        <template #cell(plan_price)="row">
          R${{ row.item.plan.plan_price }}
        </template>
        <template #cell(payment)="row">
          {{ row.item.payment ? row.item.payment.status : "Não informado" }}
        </template>
        <template #cell(accounts)="row">
          R${{ row.item.bonusUser ? row.item.bonusUser.user_bonus_price : 0 | formatPrice }}
        </template>
        <template #cell(projects)="row">
          R${{
            row.item.bonusProject ? row.item.bonusProject.project_bonus_price : 0 | formatPrice
          }}
        </template>
        <template #cell(total)="row">
          R${{
            sumValuesAccount(
              row.item.plan.plan_price,
              row.item.bonusUser ? row.item.bonusUser.user_bonus_price : 0,
              row.item.bonusProject ? row.item.bonusProject.project_bonus_price : 0
            ) | formatPrice
          }}
        </template>
        <template #cell(createdAt)="row">
          <div class="d-flex align-items-center justify-content-center">
            <span class="validade">{{ row.item.createdAt | formatDateTimeNoLocal }}</span>
            <div>
              <VSIcons
                height="18"
                name="calendar"
              />
            </div>
          </div>
        </template>
        <template #cell(signatureValidUntil)="row">
          <div class="d-flex align-items-center justify-content-center">
            <span v-if="row.item.signature_valid_until === null" class="validade">Em processamento</span>
            <span v-else class="validade">{{ row.item.signature_valid_until | formatDateTimeNoLocal }}</span>
          </div>
        </template>
        <template #cell(isLegalPerson)="row">
          {{ row.item.isLegalPerson ? "PJ" : "PF" }}
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :per-page="perPage"
        :total-rows="rows"
        aria-controls="company-table"
      >
        <template #prev-text>
          <VSIcons name="ArrowLeft" />
        </template>
        <template #next-text>

          <VSIcons name="ArrowRight" />
        </template>
      </b-pagination>
    </div>
  </div>
</template>

<style scoped>
.inactive-red tr td {
  color: red;
}
</style>

<script>
import {
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BPagination,
  BSpinner,
  BTable,
  BFormSelect,
} from 'bootstrap-vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { tableConfig } from '@core/mixins/ui/forms'
import { formatDateTime, formatPrice, formatDateTimeNoLocal } from '@core/utils/filter'
import axios from 'axios'
import VSIcons from '@/assets/icons/VSIcons.vue'
import api from '@/libs/axios'

export default {
  components: {
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BTable,
    VSIcons,
    BSpinner,
    BPagination,
    BFormSelect,
  },
  filters: {
    formatDateTime,
    formatPrice,
    formatDateTimeNoLocal,
  },
  mixins: [tableConfig],
  data() {
    return {
      fields: [
        {
          label: 'ID',
          key: 'id',
          sortable: true,
          tdClass: 'align-middle',
        },
        {
          label: 'Status',
          key: 'isActive',
          sortable: true,
          tdClass: 'align-middle to-uppercase',
        },
        {
          label: 'Data de criação',
          key: 'createdAt',
          sortable: true,
          tdClass: 'align-middle to-uppercase',
        },
        {
          label: 'Data de vencimento',
          key: 'signatureValidUntil',
          sortable: true,
          tdClass: 'align-middle to-uppercase',
        },
        {
          label: 'Tipo',
          key: 'isLegalPerson',
          sortable: true,
          tdClass: 'align-middle to-uppercase',
        },
        {
          label: 'Nome',
          key: 'name',
          sortable: true,
          tdClass: 'align-middle to-uppercase',
        },
        {
          label: 'Plano',
          key: 'plan',
          sortable: true,
          tdClass: 'align-middle to-uppercase',
        },
        {
          label: 'Valor',
          key: 'plan_price',
          sortable: true,
          tdClass: 'align-middle to-uppercase',
        },
        {
          label: 'Adc. Contas',
          key: 'accounts',
          sortable: true,
          tdClass: 'align-middle to-uppercase',
        },
        {
          label: 'Adc. Empreendimento',
          key: 'projects',
          sortable: true,
          tdClass: 'align-middle to-uppercase',
        },
        {
          label: 'Total',
          key: 'total',
          sortable: true,
          tdClass: 'align-middle to-uppercase',
        },
        {
          label: 'Status Pag.',
          key: 'status',
          sortable: true,
          tdClass: 'align-middle to-uppercase',
        },
      ],
      isTrialButtonIsPressed: false,
      dataCep: null,
      phoneMask: '',
      cpf_cnpjMask: '',
      linkFatura: null,
      user: {
        name: '',
        cpf_cnpj: '',
        email: '',
        phone: '',
        zip_code: '',
        street: '',
        number: '',
        complement: '',
        phone_prefix: '',
        district: '',
        city: '',
        state: '',
        aditional_users: 0,
        contract_version: 'v1',
        contract_is_accepted: true,
        plan_identifier: '',
      },
      userValidation: {
        name: null,
        cpf_cnpj: null,
        email: null,
        phone: null,
        zip_code: null,
        street: null,
        number: null,
        district: null,
        city: null,
        state: null,
        buttonCep: true,
      },
      anyErrorForm: false,
      trialPlansToSelect: [{ value: '', text: '' }],

    }
  },
  computed: {
    ...mapGetters({ apiInCall: 'app/getApiInCall', items: 'accounts/getAccounts' }),
  },
  created() {
    this.fetchAccounts()
    this.getTrialPlans()
  },
  methods: {
    ...mapActions({ fetchAccounts: 'accounts/fetchAccounts' }),
    ...mapMutations({ setItensToDelete: 'app/SET_ITENS_DELETE' }),
    sumValuesAccount(...values) {
      const reducer = (previousValue, currentValue) => previousValue + currentValue
      return values.reduce(reducer)
    },
    detailAccounts(row) {
      this.$router.push(`gestao-verdesys/contas/${row[0].id}/detalhes`)
    },
    blockCtrlV(event) {
      if (event.ctrlKey && event.code === 'KeyV') {
        event.preventDefault();
      }
    },
    async getCep() {
      axios.get(`https://viacep.com.br/ws/${this.user.zip_code}/json/`)
        .then(response => {
          this.dataCep = response.data
          this.userValidation.zip_code = null
          this.userValidation.buttonCep = false
        })
        .catch(() => {
          this.userValidation.zip_code = false
          this.anyErrorForm = true
        })
    },
    async createTrialUser() {
      this.userValidation.buttonCep = true
      this.userValidation.phone = null
      if (this.phoneMask.length === 0 || this.phoneMask === '') {
        this.toast({
          title: 'Preencha o formulário corretamente',
          text: 'Verifique se os campos foram preenchidos corretamente e tente novamente.',
          variant: 'warning',
        })
        this.userValidation.phone = false
        this.anyErrorForm = true
      }

      const dddRegexWithParentheses = /\(\d{2}\)/
      const dddRegexWithoutParentheses = /(\d{2})/
      const regexEmail = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/
      const regexCpf = /^(\d{3})\.(\d{3})\.(\d{3})-(\d{2})$/
      const regexCnpj = /^(\d{2})\.(\d{3})\.(\d{3})\/(\d{4})-(\d{2})$/

      this.user.cpf_cnpj = this.cpf_cnpjMask
      this.userValidation.name = null
      if (this.user.name.length <= 3) {
        this.userValidation.name = false
        this.anyErrorForm = true
      }
      this.anyErrorForm = false
      this.userValidation.cpf_cnpj = null
      if (regexCnpj.test(this.user.cpf_cnpj) === false && regexCpf.test(this.user.cpf_cnpj) === false) {
        this.userValidation.cpf_cnpj = false
        this.anyErrorForm = true
      }

      if (regexCpf.test(this.user.cpf_cnpj) === true) {
        this.user.cpf_cnpj = this.user.cpf_cnpj.replace(regexCpf, '$1$2$3$4')
      }

      if (regexCnpj.test(this.user.cpf_cnpj) === true) {
        this.user.cpf_cnpj = this.user.cpf_cnpj.replace(regexCnpj, '$1$2$3$4$5')
      }
      this.anyErrorForm = false
      this.userValidation.email = null
      if (regexEmail.test(this.user.email) === false) {
        this.userValidation.email = false
        this.anyErrorForm = true
      }

      this.anyErrorForm = false
      this.userValidation.zip_code = null
      if (!this.user.zip_code || this.user.zip_code.length === 0) {
        this.userValidation.zip_code = false
        this.anyErrorForm = true
      }
      if (this.anyErrorForm) {
        this.toast({
          title: 'Preencha o formulário corretamente',
          text: 'Verifique se os campos foram preenchidos corretamente e tente novamente.',
          variant: 'warning',
        })
      }
      this.userValidation.number = null
      if (this.user.number.length === 0) {
        this.toast({
          title: 'Preencha o formulário corretamente',
          text: 'Verifique se os campos foram preenchidos corretamente e tente novamente.',
          variant: 'warning',
        })
        this.userValidation.number = false
        this.anyErrorForm = true
      }
      this.anyErrorForm = false
      const phoneNumber = this.phoneMask.replace(dddRegexWithParentheses, '')
      const firstMask = this.phoneMask.match(dddRegexWithParentheses)
      const [secondMask] = firstMask[0].match(dddRegexWithoutParentheses)

      this.user.phone = phoneNumber
      this.user.phone_prefix = secondMask
      this.user.city = this.dataCep.localidade
      this.user.street = this.dataCep.logradouro
      this.user.city = this.dataCep.localidade
      this.user.state = this.dataCep.uf
      this.user.district = this.dataCep.bairro

      api.post('/gateway/create-user', this.user).then(response => {
        this.linkFatura = response.data.secure_url
        this.user.name = ''
        this.user.cpf_cnpj = ''
        this.user.email = ''
        this.user.phone = ''
        this.user.zip_code = ''
        this.user.street = ''
        this.user.number = ''
        this.user.complement = ''
        this.user.phone_prefix = ''
        this.user.district = ''
        this.user.city = ''
        this.user.state = ''
        this.dataCep = null

        this.toast({
          title: 'Conta Criada com sucesso',

          variant: 'success',
        })
        this.userValidation.buttonCep = false
      }).catch(() => {
        this.userValidation.buttonCep = false
        this.toast({
          title: 'Algum dado foi enviado incorretamente ou estamos com problemas técnicos',
          text: 'Verifique se os campos foram preenchidos corretamente e tente novamente.',
          variant: 'erro',
        })
      })
    },
    async getTrialPlans() {
      const { data } = await api.get('/plan/trial-plans')
      console.log(data)
      data.forEach(plans => (

        this.trialPlansToSelect.push({ value: plans.plan_identifier, text: plans.plan_name })

      ))
    },
  },
}
</script>
