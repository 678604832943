<template>
  <div class="company-page">
    <b-input-group class="input-group-merge round search-input-filter">
      <b-input-group-prepend
        is-text
      >
        <VSIcons
          name="Search"
          color="#7D8E9E"
          width="18"
          height="18"
        />
      </b-input-group-prepend>
      <b-form-input
        id="filter-input"
        v-model="filter"
        placeholder="Buscar..."
        type="search"
      />
    </b-input-group>
    <b-table
      id="company-table"
      ref="companyTable"
      class="details-table mt-4"
      :items="items"
      :fields="fields"
      hover
      responsive
      details-td-class="align-middle"
      :busy="apiInCall"
      :per-page="perPage"
      :current-page="currentPage"
      selectable
      select-mode="single"
      :filter="filter"
      :filter-included-fields="filterOn"
      empty-filtered-text="Não há resultados para sua pesquisa."
      empty-text="Não há registros para serem exibidos."
      show-empty
      @filtered="onFiltered"
      @row-selected="detailFeedback"
    >
      <template #table-busy>
        <b-spinner variant="primary" />
      </template>
      <template #head(select)>
        <b-form-checkbox
          v-model="allSelected"
          :indeterminate="indeterminate"
          @change="toggleAll('feedback', 'Feedback')"
        />
      </template>
      <template #cell(select)="row">
        <b-form-checkbox
          v-model="selected"
          :value="row.item"
          @change="setItensToDelete({ itens: selected, api: 'feedback', fetchTable: 'Feedback' })"
        />
      </template>

      <template #cell(user)="row">
        <span> {{ row.item.user.full_name ? row.item.user.full_name : 'Sem nome' }}</span>
      </template>

      <template #cell(email)="row">
        <span> {{ row.item.user.email }}</span>
      </template>

      <template #cell(createdAt)="row">
        <div class="d-flex align-items-center justify-content-center">
          <span class="validade">{{ getDate(row.item.createdAt) }}</span>
          <div>
            <VSIcons
              name="calendar"
              height="18"
            />
          </div>
        </div>
      </template>

      <template #cell(reaction)="row">
        <VSIcons
          :name="row.item.reaction"
          height="18"
        />
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="company-table"
    >
      <template #prev-text>
        <VSIcons name="ArrowLeft" />
      </template>
      <template #next-text>
        <VSIcons name="ArrowRight" />
      </template>
    </b-pagination>
    <VSModal
      name="DetailFeedback"
      :properties="feedbackData"
      width="940"
    />
  </div>
</template>

<script>
import {
  BInputGroup, BInputGroupPrepend, BFormInput, BTable, BFormCheckbox, BSpinner, BPagination,
} from 'bootstrap-vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { tableConfig } from '@core/mixins/ui/forms'
import VSIcons from '@/assets/icons/VSIcons.vue'
import VSModal from '@/components/modals/VSModal.vue'

export default {
  components: {
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BTable,
    VSIcons,
    BFormCheckbox,
    BSpinner,
    BPagination,
    VSModal,
  },
  mixins: [
    tableConfig,
  ],
  data() {
    return {
      fields: [
        {
          label: '',
          key: 'select',
        },
        {
          label: 'Data',
          key: 'createdAt',
          sortable: true,
          tdClass: 'align-middle to-uppercase',
        },
        {
          label: 'Reação',
          key: 'reaction',
          sortable: true,
          tdClass: 'align-middle to-uppercase',
        },
        {
          label: 'Título',
          key: 'subject',
          sortable: true,
          tdClass: 'align-middle to-uppercase',
        },
        {
          label: 'Nome',
          key: 'user',
          sortable: true,
          tdClass: 'align-middle to-uppercase',
        },
        {
          label: 'E-mail',
          key: 'email',
          sortable: true,
          tdClass: 'align-middle',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({ apiInCall: 'app/getApiInCall', feedbackData: 'feedback/getFeedbackData', items: 'feedback/getFeedbacks' }),
  },
  created() {
    this.fetchFeedbacks()
  },
  methods: {
    ...mapMutations({
      setItensToDelete: 'app/SET_ITENS_DELETE',
    }),
    ...mapActions({ fetchFeedbackById: 'feedback/fetchFeedbackById', fetchFeedbacks: 'feedback/fetchFeedbacks' }),
    getDate(date) {
      const correctDate = new Date(date)
      return correctDate.toLocaleString('pt-br').split(' ')[0]
    },
    detailFeedback(items) {
      this.fetchFeedbackById(items[0].id)
      this.showModal('DetailFeedback')
    },
  },
}
</script>
