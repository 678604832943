<template>
  <div>
    <b-tabs content-class="mt-3">
      <b-tab
        title="Gestão de contas"
        active
      >
        <ManagementVerdeSysAccounts />
      </b-tab>
      <b-tab title="Gestão de recebimento">
        <ManagementVerdeSysReceivement />
      </b-tab>
      <b-tab title="Gestão de feedback">
        <ManagementVerdeSysFeedbacks />
      </b-tab>
      <b-tab title="Gestão de tickets">
        <TicketsData />
      </b-tab>
      <b-tab title="Gestão de notificações">
        <ManagementVerdeSysNotifications />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import ManagementVerdeSysAccounts from './ManagementVerdeSysAccounts.vue'
import ManagementVerdeSysReceivement from './ManagementVerdeSysReceivement.vue'
import ManagementVerdeSysFeedbacks from './ManagementVerdeSysFeedbacks.vue'
import ManagementVerdeSysNotifications from './ManagementVerdeSysNotifications.vue'
import TicketsData from '../../views/tickets/TicketsData.vue'

export default {
  components: {
    BTabs,
    BTab,
    ManagementVerdeSysAccounts,
    ManagementVerdeSysReceivement,
    ManagementVerdeSysFeedbacks,
    ManagementVerdeSysNotifications,
    TicketsData,
  },
}
</script>
