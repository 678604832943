<template>
  <ManagementVerSys />
</template>

<script>
import ManagementVerSys from '@/modules/management-verdesys/ManagementVerdeSys.vue'

export default {
  components: {
    ManagementVerSys,
  },
}
</script>

<style>

</style>
