<template>
  <div class="payment-page">
    <div
      class="btn-actions-content"
    >
      <b-button
        class="btn-grad d-flex align-items-center"
        @click="openModal({}, 'create')"
      >
        <VSIcons
          name="AddSquare"
          width="22"
          height="22"
          class="pr-2"
        />
        <span class="align-middle">Adicionar</span>
      </b-button>
    </div>
    <b-input-group class="input-group-merge round search-input-filter">
      <b-input-group-prepend
        is-text
      >
        <VSIcons
          name="Search"
          color="#7D8E9E"
          width="18"
          height="18"
        />
      </b-input-group-prepend>
      <b-form-input
        id="filter-input"
        v-model="filter"
        placeholder="Buscar..."
        type="search"
      />
    </b-input-group>
    <b-table
      id="payment-table"
      ref="payment"
      class="details-table mt-4"
      :items="items"
      :fields="fields"
      hover
      responsive
      details-td-class="align-middle"
      :busy="apiInCall"
      :per-page="perPage"
      :current-page="currentPage"
      :filter="filter"
      :filter-included-fields="filterOn"
      empty-filtered-text="Não há resultados para sua pesquisa."
      empty-text="Não há registros para serem exibidos."
      show-empty
      @filtered="onFiltered"
    >
      <template #table-busy>
        <b-spinner variant="primary" />
      </template>
      <template #head(select)>
        <b-form-checkbox
          v-model="allSelected"
          :indeterminate="indeterminate"
          @change="toggleAll('payment', '')"
        />
      </template>
      <template #cell(select)="row">
        <b-form-checkbox
          v-model="selected"
          :value="row.item"
          @change="setItensToDelete({ itens: selected, api: '', fetchTable: 'Receivment' })"
        />
      </template>
      <template #cell(sendDate)="row">
        {{ row.item.sendDate | formatDateTimeNoLocal }}
      </template>
      <template #cell(actions)="row">
        <b-dropdown
          no-caret
          right
          variant="light"
          menu-class="dropdown-table-actions"
          toggle-class="button-dropdown-actions"
        >
          <template #button-content>
            <VSIcons
              name="Actions"
              width="5"
              height="22"
            />
          </template>
          <b-dropdown-item
            class="dropdown-table-item"
            @click="openModal(row.item, 'read')"
          >
            <VSIcons
              name="Eye"
              width="21"
              height="21"
              color="#7D8E9E"
            />
            <span>
              Ver
            </span>
          </b-dropdown-item>
          <b-dropdown-divider />
          <b-dropdown-item
            class="dropdown-table-item"
            @click="openModal(row.item, 'edit')"
          >
            <VSIcons
              name="Edit"
              width="20"
              height="20"
              color="#7D8E9E"
            />
            <span>
              Editar
            </span>
          </b-dropdown-item>
          <b-dropdown-divider />
          <b-dropdown-item
            class="dropdown-table-item"
            @click="deleteItem(row.item)"
          >
            <VSIcons
              name="TrashSquare"
              width="21"
              height="21"
              color="#7D8E9E"
            />
            <span>
              Excluir
            </span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="company-table"
    >
      <template #prev-text>
        <VSIcons name="ArrowLeft" />
      </template>
      <template #next-text>
        <VSIcons name="ArrowRight" />
      </template>
    </b-pagination>
    <VSModal
      width="900"
      name="CreateNotification"
      :properties="properties"
      :draggable="false"
    />
  </div>
</template>

<script>
import {
  BInputGroup, BInputGroupPrepend, BFormInput, BTable, BFormCheckbox, BSpinner, BPagination, BDropdown, BDropdownItem, BButton, BDropdownDivider,
} from 'bootstrap-vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { tableConfig } from '@core/mixins/ui/forms'
import { formatPrice, formatLocalDate, formatDateTimeNoLocal } from '@core/utils/filter'
import VSIcons from '@/assets/icons/VSIcons.vue'
import VSModal from '@/components/modals/VSModal.vue'

export default {
  components: {
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BTable,
    BFormCheckbox,
    BSpinner,
    BPagination,
    BDropdown,
    BDropdownItem,
    BButton,
    BDropdownDivider,
    VSIcons,
    VSModal,
  },
  filters: {
    formatPrice,
    formatLocalDate,
    formatDateTimeNoLocal,
  },
  mixins: [
    tableConfig,
  ],
  data() {
    return {
      properties: {
        action: 'create',
        item: {},
      },
      fields: [
        {
          label: '',
          key: 'select',
        },
        {
          label: 'Situação',
          key: 'status',
          sortable: true,
          tdClass: 'align-middle to-uppercase',
          formatter: this.handleSituation,
        },
        {
          label: 'Tipo',
          key: 'type',
          sortable: true,
          tdClass: 'align-middle to-uppercase',
        },
        {
          label: 'Título',
          key: 'subject',
          sortable: true,
          tdClass: 'align-middle to-uppercase',
        },
        {
          label: 'Data de envio',
          key: 'sendDate',
          sortable: true,
          tdClass: 'align-middle to-uppercase',
        },
        {
          label: '',
          key: 'actions',
          tdClass: 'align-middle',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({ apiInCall: 'app/getApiInCall', items: 'announcements/getAnnouncements' }),
  },
  created() { this.fetchAnnouncements() },
  methods: {
    ...mapActions({ fetchAnnouncements: 'announcements/fetchAnnouncements' }),
    ...mapMutations({
      setItensToDelete: 'app/SET_ITENS_DELETE',
    }),
    openModal(row, action) {
      this.properties.item = row
      this.properties.action = action
      this.showModal('CreateNotification')
    },
    handleSituation(value) {
      if (value === 'Read') {
        return 'Lido'
      } if (value === 'Created') {
        return 'Ainda não enviado'
      } return 'Não lido'
    },
  },
}
</script>
